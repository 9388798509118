export const DROPS = [
  {
    title: 'Steve Aoki live erleben.',
    subtitle: `
      Von Japan nach Deutschland: Schon bald <b>bringt Steve Aoki das
      Bootshaus Köln</b> zum Beben. Beim Gewinnspiel kein Glück gehabt?
      Ab 18.11. kannst Du Dir Deinen Platz auf der Gästeliste im IQOS
      Store in Köln sichern.
    `,
    date: '4.11.',
    locked: true,
    imageSet: {
      alt: 'Steve Aoki Looking Back',
      mobile: '/steve-aoki/drops/bg-drop-1-looking-back-s',
      desktop: '/steve-aoki/drops/bg-drop-1-looking-back-xl',
    },
    actionName: null,
    icsFile: null,
  },
  {
    title: 'Drop #2: <br /> 1 von 20 IQOS x Steve Aoki Jacket gewinnen.',
    subtitle:
      'Unser Zeichen für mehr Nachhaltigkeit. Ein <b>Fashion Piece einer echten Stilikone</b>. Du hattest die exklusive Chance Dir 1 von weltweit 1000 leuchtenden Exemplaren zu sichern.',
    date: '11.11.',
    locked: true,
    imageSet: {
      alt: 'IQOS x Steve Aoki Jacket',
      mobile: '/steve-aoki/drops/bg-steve-aoki-jacket-s',
      desktop: '/steve-aoki/drops/bg-steve-aoki-jacket-xl',
    },
    actionName: 'SteveAoki_Drop2_Reminder',
    icsFile: '/steve-aoki/steve-aoki-drop-2.ics',
  },
  {
    title: 'Von Tokyo nach Deutschland. Film ab!',
    subtitle:
      'Blicken wir gemeinsam auf das große Highlight in Japan zurück, bevor wir Steve Aoki schon bald nach Köln holen. Freue Dich auf unseren Film <b>am 18.11.</b>!',
    date: '18.11.',
    locked: true,
    imageSet: {
      alt: 'IQOS TogetherXMusic Tokyo Flyer',
      mobile: '/steve-aoki/drops/bg-tokyo-germany-movie-s',
      desktop: '/steve-aoki/drops/bg-tokyo-germany-movie-xl',
    },
    actionName: 'SteveAoki_Drop3_Reminder',
    icsFile: '/steve-aoki/steve-aoki-drop-3.ics',
  },
  {
    title: 'Du siehst sie zuerst: Die neue Limited Edition.',
    subtitle:
      'Diese besonderen Limited Edition ist mit einem Aufdruck versehen, der an die exklusive Zusammenarbeit erinnert. Entdecke sie schon am <b>28.11.</b>!',
    date: '28.11.',
    locked: true,
    imageSet: {
      alt: 'Steve Aoki Limited Edition',
      mobile: '/steve-aoki/drops/bg-steve-aoki-limited-edition-s',
      desktop: '/steve-aoki/drops/bg-steve-aoki-limited-edition-xl',
    },
    actionName: 'SteveAoki_Drop4_Reminder',
    icsFile: '/steve-aoki/steve-aoki-drop-4.ics',
  },
  {
    title: 'Wieder da: Der IQOS Adventskalender!',
    subtitle:
      'Im Dezember ist jeder Tag ein Gewinn. Freue Dich auf den neuen IQOS Adventskalender und <b>ab dem 2.12.</b> auf die IQOS ILUMA x Steve Aoki 10 Year Edition!',
    date: '02.12.',
    locked: true,
    imageSet: {
      alt: 'Der IQOS Adventskalender',
      mobile: '/steve-aoki/drops/bg-iqos-adventskalender-s',
      desktop: '/steve-aoki/drops/bg-iqos-adventskalender-xl',
    },
    actionName: 'SteveAoki_Drop5_Reminder',
    icsFile: '/steve-aoki/steve-aoki-drop-5.ics',
  },
];
